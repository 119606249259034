var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "a-select",
              {
                staticStyle: { width: "150px" },
                attrs: {
                  placeholder: "选择群聊",
                  size: "small",
                  "filter-option": _vm.untils.filterOption,
                  showSearch: ""
                },
                on: { change: _vm.changeGroup },
                model: {
                  value: _vm.currentId,
                  callback: function($$v) {
                    _vm.currentId = $$v
                  },
                  expression: "currentId"
                }
              },
              _vm._l(_vm.groupList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.chatId } },
                  [_vm._v(_vm._s(item.chatName))]
                )
              }),
              1
            ),
            _c(
              "a-popconfirm",
              {
                attrs: {
                  title: "确认删除本页数据么?",
                  "ok-text": "是",
                  "cancel-text": "否"
                },
                on: {
                  confirm: function($event) {
                    return _vm.removeMessage("all", _vm.data)
                  },
                  cancel: _vm.cancel
                }
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { margin: "0 10px" },
                    attrs: { size: "small" }
                  },
                  [_vm._v("删除本页")]
                )
              ],
              1
            ),
            _c(
              "a-button",
              { attrs: { size: "small" }, on: { click: _vm.openModal } },
              [_vm._v("发布")]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  margin: "0 10px"
                }
              },
              _vm._l(_vm.cell_urls, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "img-box" },
                  [
                    _c("img", {
                      staticStyle: {
                        "max-width": "30px",
                        "max-height": "30px"
                      },
                      attrs: { src: item, alt: "" },
                      on: {
                        click: function($event) {
                          return _vm.previewImg(item)
                        }
                      }
                    }),
                    _c("a-icon", {
                      staticStyle: {
                        position: "absolute",
                        top: "-5px",
                        right: "-5px"
                      },
                      attrs: { type: "close-circle" },
                      on: {
                        click: function($event) {
                          return _vm.removeImg(_vm.cell_urls, index)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              "row-selection": _vm.rowSelection,
              pagination: false,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            scopedSlots: _vm._u([
              {
                key: "user",
                fn: function(row, d, e) {
                  return _c("div", {}, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.checkrowNames(row, d, e)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(row.senderUser.nickname) + " ")]
                      ),
                      row.senderUser.receptReportNum ||
                      row.senderUser.receptBlockedNum > 0
                        ? _c("div", [
                            _vm._v("标记假货:"),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(row.senderUser.receptReportNum))
                            ]),
                            _vm._v("，被屏蔽:"),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(row.senderUser.receptBlockedNum))
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                }
              },
              {
                key: "content",
                fn: function(row) {
                  return _c("div", {}, [
                    row.msgtype == 3
                      ? _c("img", {
                          staticStyle: {
                            "max-width": "100px",
                            "max-height": "100px"
                          },
                          attrs: { src: row.filepath, alt: "" },
                          on: {
                            click: function($event) {
                              return _vm.previewImg(row.filepath)
                            }
                          }
                        })
                      : _c("div", [_vm._v(_vm._s(row.content))])
                  ])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      row.msgtype == 3
                        ? _c(
                            "a-button",
                            {
                              staticStyle: { "margin-right": "5px" },
                              attrs: { size: "small", type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.setReport(row)
                                }
                              }
                            },
                            [_vm._v("标记假货")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.removeMessage(1, row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "10px" },
              on: {
                click: function($event) {
                  return _vm.downPage("next")
                }
              }
            },
            [_vm._v("上一页")]
          ),
          _c(
            "a-button",
            {
              staticStyle: { "margin-left": "10px" },
              on: {
                click: function($event) {
                  return _vm.downPage("pre")
                }
              }
            },
            [_vm._v("下一页")]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { keyboard: false, width: 800, title: "发布货品" },
          on: { ok: _vm.hideModal, cancel: _vm.resetForms },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c("a-form-model-item", { attrs: { label: "发布者名称" } }, [
                _vm._v(" " + _vm._s(_vm.forms.nickname) + " ")
              ]),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择币种", prop: "coinId" } },
                [
                  _c(
                    "a-select",
                    {
                      ref: "addse",
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      on: { search: _vm.searchData },
                      model: {
                        value: _vm.forms.coinId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinId", $$v)
                        },
                        expression: "forms.coinId"
                      }
                    },
                    _vm._l(_vm.currency_big_list, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [
                          _vm._v(_vm._s(item.coinKindName)),
                          item.dynastyName
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.dynastyName))
                              ])
                            : _vm._e(),
                          item.dynastyName
                            ? _c("span", [_vm._v(" " + _vm._s(item.rulerName))])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "货品名称" } },
                [
                  _c("a-textarea", {
                    attrs: { "auto-size": "" },
                    model: {
                      value: _vm.forms.productShelvesName,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "productShelvesName", $$v)
                      },
                      expression: "forms.productShelvesName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "货品价格" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.forms.price,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "price", $$v)
                      },
                      expression: "forms.price"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "货品备注" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.forms.productShelvesRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "productShelvesRemark", $$v)
                      },
                      expression: "forms.productShelvesRemark"
                    }
                  })
                ],
                1
              ),
              _c("a-form-model-item", { attrs: { label: "商品图" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  _vm._l(_vm.forms.urls, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: {
                          margin: "5px 10px",
                          position: "relative"
                        }
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            "max-width": "50px",
                            "max-height": "50px"
                          },
                          attrs: { src: item, alt: "" },
                          on: {
                            click: function($event) {
                              return _vm.previewImgs(_vm.forms.urls, index)
                            }
                          }
                        }),
                        _c("a-icon", {
                          staticStyle: {
                            position: "absolute",
                            top: "-5px",
                            right: "-5px"
                          },
                          attrs: { type: "close-circle" },
                          on: {
                            click: function($event) {
                              return _vm.removeImg(_vm.forms.urls, index)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }